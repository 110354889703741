<template>
  <!--
    The view for the DeviceDetails-component.
    Used to display details about one device.
  -->
  <DeviceDetail 
    :key="`deviceDetail-${ deviceId }-${ installationId }`" 
    :installation-id="installationId"
    :device-id="deviceId"
    :conf="conf"
  />
</template>

<script>
export default {
  name: "DeviceView",
  components: {
    DeviceDetail: () => import('@/components/Device/DeviceDetail/DeviceDetail.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    },
    conf: {
      type: String,
      required: false,
      default: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('deviceView.deviceDetail')
    }
  }
}
</script>